import { useEffect } from 'react';

function App() {
  useEffect(() => {
    window.location.href = 'https://www.museedupatriarcat.com/';
  }, []);

  return <></>;
}

export default App;
